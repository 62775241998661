import React from 'react'
import { object } from 'prop-types'
import { Pagination } from 'antd'
import { graphql, navigate, Link } from 'gatsby'
import Img from 'gatsby-image'
import { injectIntl } from 'react-intl'
import moment from 'moment'

import SEO from 'components/seo'
import HtmlContent from 'components/HtmlContent'
import { getHomeSlug } from 'utils'
import styles from './postList.module.less'

const PostList = ({ intl, data, pageContext }) => {
  const {
    postList: { pageInfo, edges }
  } = data
  const homeSlug = getHomeSlug(pageContext)

  return (
    <>
      <SEO title={intl.formatMessage({ id: 'postList.title' })} />
      <div className={styles.newsPage}>
        <div className="container">
          <ul className={styles.postList}>
            {edges.map(({ node }) => {
              const { id, fields, frontmatter, excerpt } = node

              return (
                <li key={id}>
                  {frontmatter.featuredImage && (
                    <div className={styles.img}>
                      <Link to={fields.slug}>
                        <Img
                          fluid={
                            frontmatter.featuredImage.childImageSharp.fluid
                          }
                        />
                      </Link>
                    </div>
                  )}
                  <div className={styles.txt}>
                    <div className={styles.title}>
                      <h2>
                        <Link to={fields.slug}>{frontmatter.title}</Link>
                      </h2>
                      <p className={styles.date}>
                        <span>
                          {moment(new Date(frontmatter.date)).format(
                            intl.formatMessage({ id: 'date.format' })
                          )}
                        </span>
                      </p>
                    </div>
                    <HtmlContent className={styles.excerpt} html={excerpt} />
                    <Link to={fields.slug}>
                      {intl.formatMessage({ id: 'readMore' })} >>
                    </Link>
                  </div>
                </li>
              )
            })}
          </ul>
          <Pagination
            className={styles.pagination}
            current={pageInfo.currentPage}
            total={pageInfo.pageCount * pageInfo.perPage}
            pageSize={pageInfo.perPage}
            onChange={page =>
              navigate(
                page === 1 ? `${homeSlug}news` : `${homeSlug}news/${page}`
              )
            }
          />
        </div>
      </div>
    </>
  )
}

PostList.propTypes = {
  intl: object.isRequired,
  data: object.isRequired
}

export default injectIntl(PostList)

export const pageQuery = graphql`
  query NewsList($locale: String!, $skip: Int!, $limit: Int!) {
    postList: allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "//news//" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      pageInfo {
        currentPage
        pageCount
        perPage
      }
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`
